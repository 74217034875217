import { IPlatformAPI } from '@wix/native-components-infra/dist/src/types/types';
import { SearchDocumentType } from '@wix/client-search-sdk';
import initSchemaLogger, { Logger } from '@wix/bi-logger-wix-search-platform';

import {
  createBiCorrelationId,
  BiStoreKey,
  BiStore,
  BiSearchOrigin,
} from '../../../../lib/bi';
import { SuggestionItems } from './types';
import { getSuggestionsStats } from './getSuggestionsStats';

const correlationIdTimeoutInMilliseconds = 30 * 1000;

export function createSearchPlatformBiLogger(platformAPIs: IPlatformAPI) {
  const biLogger: Logger = initSchemaLogger(platformAPIs.biLoggerFactory?.())();
  const biStore = new BiStore(platformAPIs);

  let correlationIdLastUsedAt: number | undefined;
  let lastSuggestions: SuggestionItems | undefined;
  const shouldGenerateNewCorrelationId: () => boolean = () => {
    return (
      !correlationIdLastUsedAt ||
      Date.now() - correlationIdLastUsedAt > correlationIdTimeoutInMilliseconds
    );
  };

  const generateCorrelationIdIfNeeded = () => {
    if (shouldGenerateNewCorrelationId()) {
      const correlationId = createBiCorrelationId();
      biStore.set(BiStoreKey.SuggestionsCorrelation, correlationId);
      biStore.set(BiStoreKey.SearchCorrelation, correlationId);
    }
    correlationIdLastUsedAt = Date.now();
  };

  return {
    searchSubmit: (params: { isDemoContent: boolean; searchQuery: string }) => {
      generateCorrelationIdIfNeeded();

      biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.EditorSearchBar);

      biLogger.searchBoxSearchSubmit({
        correlationId: biStore.get(BiStoreKey.SearchCorrelation),
        isDemo: params.isDemoContent,
        target: params.searchQuery,
      });
    },

    resetRequestCorrelationId: () => {
      correlationIdLastUsedAt = undefined;
    },

    searchBoxSuggestionsRequestStarted: (startParams: {
      searchQuery: string;
    }) => {
      generateCorrelationIdIfNeeded();
      const startTime = Date.now();

      const commonProps = {
        correlationId: biStore.get(BiStoreKey.SuggestionsCorrelation),
        target: startParams.searchQuery,
      };

      biLogger.searchBoxSuggestionsRequestStarted(commonProps);

      return function searchBoxSuggestionsRequestFinished(finishParams: {
        success: boolean;
        error?: string;
        suggestions: SuggestionItems;
      }) {
        const loadingDuration = Date.now() - startTime;

        if (finishParams.success) {
          lastSuggestions = finishParams.suggestions;
          const {
            resultCount,
            resultsArray,
            documentIds,
          } = getSuggestionsStats(finishParams.suggestions);

          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            documentIds,
            loadingDuration,
            resultCount,
            resultsArray,
            success: true,
          });
        } else {
          lastSuggestions = undefined;
          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            error: finishParams.error,
            loadingDuration,
            success: false,
          });
        }
      };
    },

    searchBoxSuggestionClick: (params: {
      title: string;
      url: string;
      searchQuery: string;
      index: number;
      documentType: string;
      suggestions: SuggestionItems;
    }) => {
      const { resultsArray } = getSuggestionsStats(params.suggestions);
      const correlationId = biStore.get(BiStoreKey.SuggestionsCorrelation);

      const clickedSuggestion = params.suggestions.find(
        (s) => s.url === params.url,
      );

      // 99:243 searchBox.suggestResults.click
      // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/243?artifactId=com.wixpress.wix-search-platform
      biLogger.searchBoxSuggestionClick({
        correlationId,
        documentId: clickedSuggestion?.id,
        documentType: params.documentType,
        pageUrl: params.url,
        resultsArray,
        searchIndex: params.index,
        target: params.searchQuery,
        resultClicked: params.title,
      });
    },

    searchBoxSuggestionSearchAllClick: (params: { searchQuery: string }) => {
      biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.EditorSearchBar);
      biLogger.searchBoxSuggestionShowAllClick({
        correlationId: biStore.get(BiStoreKey.SuggestionsCorrelation),
        // NOTE: what to do if there is only one tab? (so no All tab)
        documentType: SearchDocumentType.All,
        target: params.searchQuery,
      });
    },

    searchBoxSuggestionShowAllClick: (params: {
      searchQuery: string;
      documentType: string;
    }) => {
      biStore.set(BiStoreKey.SearchOrigin, BiSearchOrigin.EditorSearchBar);
      biLogger.searchBoxSuggestionShowAllClick({
        correlationId: biStore.get(BiStoreKey.SuggestionsCorrelation),
        documentType: params.documentType,
        target: params.searchQuery,
      });
    },
    searchBoxFocused: (params: { isDemo: boolean; isFullscreen: boolean }) => {
      biLogger.searchBoxFocused({
        isDemo: params.isDemo,
        isFullscreen: params.isFullscreen,
      });
    },
    searchBoxStartedWritingAQuery: (params: { isDemo: boolean }) => {
      biLogger.searchBoxStartedWritingAQuery({
        isDemo: params.isDemo,
      });
    },
    searchBoxCleared: (params: { isDemo: boolean; searchQuery: string }) => {
      const { resultsArray } = getSuggestionsStats(lastSuggestions || []);
      biLogger.searchBoxCleared({
        correlationId: biStore.get(BiStoreKey.SuggestionsCorrelation),
        isDemo: params.isDemo,
        target: params.searchQuery,
        resultsArray,
      });
    },
  };
}
